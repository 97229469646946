export default {
    name: "global",
    store() {
        return {
            isMobileMenuVisible: false,
            isMinicartVisible: false,
            isImageModalVisible: false,
            modalImageSrc: "",
            isPredictiveSearchVisible: false,
            isWindowScrolled: false,
            cart: null,
            init() {
                window.addEventListener("scroll", this.onWindowScrollHandler.bind(this));

                this.initLiquidAJaxCart();
            },
            get bodyClasses() {
                let classes = [];

                if (this.isMobileMenuVisible) {
                    classes.push("mobile-menu-visible");
                }

                return classes || "";
            },
            openMobileMenu() {
                this.isMobileMenuVisible = true;
                document.body.classList.add("overflow-hidden");
            },
            closeMobileMenu() {
                this.isMobileMenuVisible = false;
                document.body.classList.remove("overflow-hidden");
                document.body.classList.add("overflow-auto");
            },
            toggleMobileMenu() {
                this.isMobileMenuVisible = !this.isMobileMenuVisible;
                if (this.isMobileMenuVisible) {
                    document.body.classList.add("overflow-hidden");
                } else {
                    document.body.classList.remove("overflow-hidden");
                }
            },
            initLiquidAJaxCart() {
                document.addEventListener("liquid-ajax-cart:request-end", (event) => {
                    const { requestState, cart, previousCart, sections } = event.detail;

                    if (requestState.requestType === "add") {
                        if (requestState.responseData?.ok) {
                            this.isMinicartVisible = true;
                        }
                    }

                    this.cart = cart;
                });
            },
            onWindowScrollHandler() {
                const isScrolled = window.scrollY > 0;

                this.isWindowScrolled = isScrolled;
                document.body.classList[isScrolled ? "add" : "remove"]("scrolled");

                if (isScrolled) {
                    setTimeout(() => {
                        if (this.isWindowScrolled) {
                            const promoBar = document.querySelector("#shopify-section-promo-bar");
                            if (promoBar) {
                                promoBar.style.display = "none";
                            }
                        }
                    }, 150);
                } else {
                    const promoBar = document.querySelector("#shopify-section-promo-bar");
                    if (promoBar) {
                        promoBar.style.display = "block";
                    }
                }
            },
        };
    },
};
