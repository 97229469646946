import "liquid-ajax-cart";

import Alpine from "alpinejs";
import AlpineCollapse from "@alpinejs/collapse";
import AlpineFocus from "@alpinejs/focus";
import AlpineMorph from "@alpinejs/morph";
import AlpineWire from "../js/alpine/plugins/wire";
import AlpineGlobals from "../js/alpine/index.js";
import helpers, { hasBodyClass } from "../js/helpers.js";

// Swiper
import Swiper from "swiper";
import { Navigation, Scrollbar } from "swiper/modules";
import "swiper/swiper-bundle.css";

// Attach Swiper and its modules to the window object
window.Swiper = Swiper;
window.Navigation = Navigation;
window.Scrollbar = Scrollbar;

document.addEventListener("alpine:init", () => {
    Alpine.data("productMedia", () => ({
        currentSlide: 0,
        bigImage: "",
        bigImageModal: "",
        productSlideshow: null,
        initSwiper() {
            this.productSlideshow = new Swiper(this.$refs.slideshow, {
                modules: [Navigation],
                slidesPerView: 6,
                spaceBetween: 10,
                centeredSlides: true,
                navigation: {
                    nextEl: ".swiper-next",
                    prevEl: ".swiper-prev",
                    disabledClass: "disabled",
                },
                slideActiveClass: "active",
                breakpoints: {
                    320: {
                        slidesPerView: 3,
                    },
                    640: {
                        slidesPerView: 5,
                    },
                },
            });
            this.updateBigImage();

            this.productSlideshow.on("slideChangeTransitionEnd", () => {
                this.updateBigImage();
            });
        },

        getThis() {
            return this;
        },

        updateSlideshow() {
            let slides = Array.from(this.$refs.slideshow.querySelectorAll(".product-media-slide"));
            let seenImages = new Set();

            slides.forEach((slide) => {
                let img = slide.querySelector(".product-media-slide-image");
                let url = new URL(img.src);
                let filename = url.pathname.split("/").pop().split("_")[0];

                if (seenImages.has(filename)) {
                    slide.remove();
                } else {
                    seenImages.add(filename);
                }
            });
        },

        updateSlideshowForVariant(event) {
            if (!event) return;
        
            let slides = Array.from(this.$refs.slideshow.querySelectorAll(".swiper-slide"));
        
            let index = slides.findIndex(slide => {
                let img = slide.querySelector("img");
                return img.src.includes(event.featured_image);
            });
        
            if (index !== -1) {
                this.slideTo(index);
            } else {
                this.slideTo(0);
            }
        },

        updateBigImage(imageSrc) {
            if (!imageSrc) {
                let activeSlide = this.productSlideshow.slides[this.productSlideshow.activeIndex];
                imageSrc = activeSlide.querySelector(".product-media-slide-image").src;
            }
            this.bigImage = imageSrc;
            this.bigImageModal = imageSrc.replace("335x335", "2048x2048");
        },

        slideTo(index) {
            this.productSlideshow.slideTo(index);
        },

        init() {
            this.initSwiper();
            window.productMedia = this.getThis();
        },
    }));
});

window.swiper = new Swiper(".swiper-image-cards", {
    modules: [Navigation],
    slidesPerView: 5,
    spaceBetween: 10,
    navigation: {
        nextEl: ".swiper-next",
        prevEl: ".swiper-prev",
        disabledClass: "disabled",
    },
    breakpoints: {
        320: {
            slidesPerView: 1.1,
        },
        480: {
            slidesPerView: 2.25,
        },
        1024: {
            slidesPerView: 3,
        },
        1280: {
            slidesPerView: 4,
        },
    },
});

window.swiper = new Swiper(".swiper-image-slideshow", {
    modules: [Navigation, Scrollbar],
    slidesPerView: 5,
    spaceBetween: 10,
    navigation: {
        nextEl: ".swiper-next",
        prevEl: ".swiper-prev",
        disabledClass: "disabled",
    },
    scrollbar: {
        el: ".swiper-scrollbar",
        hide: false,
    },
    breakpoints: {
        320: {
            slidesPerView: 1,
        },
        1024: {
            slidesPerView: 2,
        },
        1280: {
            slidesPerView: 3,
        },
    },
});

window.swiper = new Swiper(".swiper-product-slider", {
    modules: [Navigation, Scrollbar],
    slidesPerView: 5,
    spaceBetween: 10,
    navigation: {
        nextEl: ".swiper-next",
        prevEl: ".swiper-prev",
        disabledClass: "disabled",
    },
    scrollbar: {
        el: ".swiper-scrollbar",
        hide: false,
    },
    breakpoints: {
        320: {
            slidesPerView: 2,
        },
        480: {
            slidesPerView: 3,
        },
        640: {
            slidesPerView: 4,
        },
        1280: {
            slidesPerView: 5,
        },
    },
});

// Dynamic imports
hasBodyClass("product-template") && import("../js/prodify.js");

const ns = "slayed";

window.slayedNamespace = ns;
window[ns] = window[ns] || {};
window[ns].helpers = helpers;

for (const [key, value] of Object.entries(helpers)) {
    window[ns].helpers[key] = value;
}

// Register and initialize AlpineJS
window.Alpine = Alpine;

Alpine.plugin([AlpineCollapse, AlpineFocus, AlpineWire, AlpineMorph]);
AlpineGlobals.register(Alpine);
Alpine.start();

document.addEventListener("DOMContentLoaded", function () {
    var images = document.querySelectorAll(".focal-point-image");
    images.forEach(function (image) {
        var focalX = parseFloat(image.getAttribute("data-focal-x")) || 0.5;
        var focalY = parseFloat(image.getAttribute("data-focal-y")) || 0.5;
        image.style.objectPosition = focalX + "% " + focalY + "%";
    });
});

// Hide the Shopify preview bar when in development
// if (process.env.NODE_ENV === 'development') {
//   //
//   window.addEventListener('DOMContentLoaded', () => {
//     var css = '#preview-bar-iframe { display: none !important; }',
//       head = document.head || document.getElementsByTagName('head')[0],
//       style = document.createElement('style')

//     head.appendChild(style)

//     style.appendChild(document.createTextNode(css))
//   })
// }
